/** Cosmos 954: Carousel School **/

import React from "react";
import { gsap } from "gsap"
import { preloadImages } from "../../helpers"
import PrevNext from "../../components/navigation/prevnext"
import SEO from "../../components/seo"
import { showText, hideText, positionText, resetAnimationDelay } from "../../helpers"
import Carousel from "../../components/media/carousel";
import SlideshowController from "../../components/media/slideshow-controller";

let globalState, data, preloadData, slideshowData, autoAdvanceInterval, voTimeout;

class CosmosPage4 extends React.Component {

  constructor(props) {
    super(props);

    //update global state and assets data
    globalState = this.props.location.state;
    data = globalState.assets[4];
    preloadData = globalState.assets[5];

    slideshowData = [
      {
        file: globalState.cdnUrl + data.slide1,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.slide2,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.slide3,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.slide4,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.slide5,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.slide6,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.slide7,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.slide8,
        fileType: 'image'
      }
    ]



    //refs
    this.fullscreenBg = null;
  }

  state = {
    textVisible: false,
    currentSlide: 1,
    autoAdvance: 6000
  }




  componentDidMount = () => {

    setTimeout(() => {
      //this.refs.carouselRef.playVideos();
    }, globalState.transitionDuration);


    //Set autoAdvance
    this.setAutoAdvance();

    //position text
    positionText(this.refs.text1Ref, "left", "top", globalState.textPosition.leftTop);
    positionText(this.refs.text2Ref, "right", "top", globalState.textPosition.rightTop);
    positionText(this.refs.captionRef, "right", "bottom", globalState.textPosition.rightBottom);


    //show text
    gsap.to(this.refs.text1Ref, 0.15, { autoAlpha: 1.0 });
    gsap.to(this.refs.text2Ref, 0.15, { autoAlpha: 1.0 });
    gsap.to(this.refs.captionRef, 0.15, { autoAlpha: 1.0 });

    //add page track
    globalState.baseAudioRef.updateTrack(1, globalState.cdnUrl + data.soundfile1);

    voTimeout = setTimeout(() => {

      //add vo track
      globalState.baseAudioRef.updateTrack(3, globalState.cdnUrl + data.soundfile3);

      //update volume base track
      globalState.baseAudioRef.updateVolume(2, 60);

    }, 1500);

    //Preload assets from next page
    preloadImages([
      globalState.cdnUrl + preloadData.text1,
      globalState.cdnUrl + preloadData.slide1,
      globalState.cdnUrl + preloadData.slide2,
      globalState.cdnUrl + preloadData.slide3,
      globalState.cdnUrl + preloadData.slide4,
      globalState.cdnUrl + preloadData.slide5,
      globalState.cdnUrl + preloadData.slide6,
      globalState.cdnUrl + preloadData.slide7,
      globalState.cdnUrl + preloadData.slide8,
    ], this.preloadCallback);

    //update the carousel 
    this.refs.carouselRef.updateCurrentSlide(this.state.currentSlide);

  }

  preloadCallback = () => {
    this.refs.prevNextRef.showPrevNextBtns(globalState.showPrevNextDelay);
  }

  componentDidUpdate() {
    this.refs.carouselRef.updateCurrentSlide(this.state.currentSlide);
  }


  componentWillUnmount() {

    //Kill any tweens
    gsap.killTweensOf(this.refs.text1Ref);

    
    //Clear vo timeout
    clearTimeout(voTimeout);
    //globalState.baseAudioRef.updateTrack(3, false);

    //Clear AutoAdvanceInterval
    clearInterval(autoAdvanceInterval);
  }



  toggleTextDisplay = (event) => {

    //toggle text visibility
    this.setState({ textVisible: !this.state.textVisible });

    //reset the animation delay
    resetAnimationDelay();

    if (this.state.textVisible) {
      //show text
      showText(this.refs.text1Ref, "left", "top", globalState.textPosition.leftTop);
      showText(this.refs.text2Ref, "right", "top", globalState.textPosition.rightTop);
      showText(this.refs.captionRef, "right", "bottom", globalState.textPosition.rightBottom);


    } else {
      //hide text
      hideText(this.refs.text1Ref, "left");
      hideText(this.refs.text2Ref, "right");
      hideText(this.refs.captionRef, "right");

    }
  }

  isTransitioning = () => {
    //this.roadVideoRef.pauseVideo();
    //this.refs.carouselRef.pauseVideos();
  }

  //Set AutoAdvance
  setAutoAdvance = () => {
    if (this.state.autoAdvance) {
      autoAdvanceInterval = setInterval(() => {
        let nextSlide = this.state.currentSlide + 1;

        if (nextSlide > slideshowData.length) {
          nextSlide = 1;
        }
        this.setState({ currentSlide: nextSlide });
      }, this.state.autoAdvance);
    }
  }

  //Stop AutoAdvance
  stopAutoAdvance = () => {
    this.setState({ autoAdvance: false });
    clearInterval(autoAdvanceInterval);
  }

  //Slideshow controls
  handleNextClick = () => {
    let nextSlide = this.state.currentSlide + 1;

    if (nextSlide > slideshowData.length) {
      nextSlide = 1;
    }

    this.setState({ currentSlide: nextSlide });
    this.stopAutoAdvance();
  }

  handleBackClick = () => {
    let nextSlide = this.state.currentSlide - 1;

    if (nextSlide < 1) {
      nextSlide = slideshowData.length;
    }

    this.setState({ currentSlide: nextSlide });
    this.stopAutoAdvance();
  }
  /*
  updateCurrentSlide = (num) => {

    for (let i = 1; i <= slideshowData.length; i++) {
      this.refs['slide' + i].classList.remove('show');
      if (i === this.state.currentSlide) {
        this.refs['slide' + i].classList.add('show');
      }
    }
  }
  */

  render() {

    return (

      <>

        <link rel="prefetch" href="/cosmos-954/5" />

        <PrevNext globalState={globalState} ref="prevNextRef"
          nextLocation="/cosmos-954/5" prevLocation="/cosmos-954/3"
          isTransitioning={this.isTransitioning} />

        {data && (
        <div className="fullpage-wrapper">
          <SEO title="Pine Point - Cosmos 954" />

          <div className="text-wrapper">
            <div className="text text--left-top" ref="text1Ref">
              <input type="image" src={globalState.cdnUrl + data.text1} style={{ marginLeft: "0", marginTop: "0" }} onClick={this.toggleTextDisplay} alt="Everyone remembers the day the high school burned down. For adults, it meant one of the pillars of the community was threatened." />
            </div>
            <div className="text text--right-top" ref="text2Ref">
              <input type="image" src={globalState.cdnUrl + data.text2} style={{ marginRight: "10px", marginTop: "0" }} onClick={this.toggleTextDisplay} alt="For the kids, having he centre of your social universe go up in smoke was equal parts nightmare and dream-come-true. ‘Wake up sweetie, your school burned to the ground this morning.’" />
            </div>
            
            <div className="text text--right-bottom" ref="captionRef">
              <input type="image" src={globalState.cdnUrl + data.caption} style={{ marginRight: "20px", marginBottom: "20px" }} onClick={this.toggleTextDisplay} alt="Matonabbee School Fire, Feb 1, 1980" />
            </div>
          
          </div>
          <SlideshowController xPos="130px" yPos="260px" handleNextClick={this.handleNextClick} handleBackClick={this.handleBackClick} state={this.state} totalSlides={slideshowData.length} />
          <Carousel ref="carouselRef" data={slideshowData} globalState={globalState} fullscreen={true} position={{ x: 0, y: 0 }} size="" state={this.state} />

        </div>
        )}
      </>
    );
  }


};

export default CosmosPage4
